.social-media_list_main-item {
	color: #ffffff;
	float: left;
	margin: 5px 0 0 10px;
	cursor: pointer;
}

.icon {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-size: cover;
}

/* Add Icons */
.icon-facebook {
	background-image: url('../SocialMediaIcons/Icons/icon_facebook.svg');
}
.icon-twitter {
	background-image: url('../SocialMediaIcons/Icons/icon_twitter.svg');
}
.icon-instagram {
	background-image: url('../SocialMediaIcons/Icons/icon_instagram.svg');
}
.icon-linkedin {
	background-image: url('../SocialMediaIcons/Icons/icon_linkedin.svg');
}
.icon-youtube {
	background-image: url('../SocialMediaIcons/Icons/icon_youtube.svg');
}

/* Add Icons */
.icon-facebook:hover,
.icon-facebook:focus {
	filter: invert(34%) sepia(11%) saturate(2792%) hue-rotate(183deg) brightness(94%) contrast(91%);
}

.icon-twitter:hover,
.icon-twitter:focus {
	filter: invert(59%) sepia(60%) saturate(6322%) hue-rotate(168deg) brightness(106%) contrast(101%);
}

.icon-instagram:hover,
.icon-instagram:focus {
	filter: invert(71%) sepia(46%) saturate(7491%) hue-rotate(333deg) brightness(96%) contrast(99%);
}

.icon-linkedin:hover,
.icon-linkedin:focus {
	filter: invert(29%) sepia(58%) saturate(2174%) hue-rotate(175deg) brightness(96%) contrast(89%) opacity(50%);
}

.icon-youtube:hover,
.icon-youtube:focus {
	filter: invert(26%) sepia(92%) saturate(7318%) hue-rotate(1deg) brightness(100%) contrast(125%);
}
