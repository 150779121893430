/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;

@import 'tailwindcss/base';

/**
 * Custom base styles, applied after the tailwind-base classes
 */

html {
	font-size: 62.5%;
	font-family: Rubik, sans-serif;
	background-color: #121212;
}

body {
	font-size: 13px;
	line-height: 1.4;
	overflow-x: hidden;
}

html,
body,
#root {
	position: relative;
	margin: 0;
	min-height: 100vh;
}

/* Hide recaptcha logo from displaying on the screen */
.grecaptcha-badge {
	visibility: hidden;
}

h1,
.h1 {
	font-size: 24px;
}

h2,
.h2 {
	font-size: 20px;
}

h3,
.h3 {
	font-size: 16px;
}

h4,
.h4 {
	font-size: 15px;
}

h5,
.h5 {
	font-size: 13px;
}

h6,
.h6 {
	font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
	z-index: 99;
}

a[role='button'] {
	text-decoration: none;
}

[role='tooltip'] {
	z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: #fff;
	}

	::-webkit-scrollbar:hover {
		width: 8px;
		height: 8px;
		background-color: #e6e7ea;
	}

	::-webkit-scrollbar-thumb {
		background-color: #b8b8bb;
		border: 2px solid #fff;
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb:hover,
	::-webkit-scrollbar-thumb:active {
		border: 2px solid #e6e7ea;
		border-radius: 20px;
	}
}

form label {
	z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
	transition: none !important;
	animation: none !important;
}

button:focus {
	outline: none;
}

@keyframes autofill {
	0%,
	100% {
		background: transparent;
	}
}

input:-webkit-autofill {
	animation-delay: 1s;
	animation-name: autofill !important;
	animation-fill-mode: both;
	-webkit-box-shadow: 0 0 0 100px transparent inset !important;
}

input[type='checkbox'] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	appearance: none;
	/* For iOS < 15 to remove gradient background */
	background-color: #fff;
	/* Not removed via appearance */
	margin: 0;
}

input[type='checkbox'] {
	appearance: none;
	background-color: #fff;
	margin-right: 5px;
	margin-top: 3px;
	font: inherit;
	color: currentColor;
	width: 0.9em;
	height: 0.9em;
	border: 0.15em solid currentColor;
	border-radius: 0.15em;
	transform: translateY(-0.075em);
	display: grid;
	place-content: center;
}

.form-control + .form-control {
	margin-top: 1em;
}

input[type='checkbox']::before {
	content: '';
	width: 0.65em;
	height: 0.65em;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em var(--form-control-color);
	transform-origin: bottom left;
	background: #6a6a6a;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type='checkbox']:checked::before {
	transform: scale(1);
}

/* input[type='checkbox']:focus {
	outline: max(2px, 0.15em) solid currentColor;
	outline-offset: max(2px, 0.15em);
} */

:focus {
	outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
	height: 1px !important;
}

/* Custom Css Begin */
.fuse-list-item-text-primary {
	font-weight: 700 !important;
	text-transform: none !important;
}

.fuse-list-item {
	background-color: transparent !important;
}

.fuse-list-item-icon {
	display: none;
}

.disclosure > tbody > tr > td {
	border: 1px solid #a3a3a3;
	border-collapse: collapse;
	padding: 5px 10px;
}

.generic_result_table {
	width: 100%;
}

.generic_result_table > tbody > tr > td {
	width: 50%;
	padding: 1rem;
}

.sigCanvas {
	border: 2px solid black;
	border-radius: 20px;
	margin: auto;
}

.otp_container {
	padding: 24px;
	text-align: center;
	margin-bottom: 16px;
	padding-bottom: 8px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 15px;
}

.react-tel-input .form-control {
	width: 100% !important;
	padding: 1.4rem !important;
	padding-left: 6rem !important;
	padding-right: 0rem !important;
	border-right: 0px !important;
}

.react-tel-input .form-control:focus {
	border-color: #6414af !important;
	box-shadow: 0 0 0 0.2rem rgb(100 20 175 / 24%) !important;
}

.react-tel-input .form-control:disabled {
	background-color: #f0f0f0 !important;
}

.file-upload-cont {
	border: 1px solid gray;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.file-upload {
	margin-bottom: 2rem;
}

.file-upload .file-upload-select {
	display: block;
	color: #dbdbdb;
	cursor: pointer;
	text-align: left;
	/* background: #1a242f; */
	overflow: hidden;
	position: relative;
	border-radius: 6px;
	margin-top: 10px;
	border: 1px solid #a5a3a3;
}

.file-upload .file-upload-select .file-select-button {
	background: #161f27;
	padding: 10px;
	display: inline-block;
}

.file-upload .file-upload-select .file-select-name {
	display: inline-block;
	padding: 10px;
	color: rgb(0, 0, 0);
}

.file-upload .file-upload-select:hover .file-select-button {
	background: #324759;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}

.file-upload .file-upload-select input[type='file'] {
	display: none;
}

.fields_grid {
	display: grid;
	grid-gap: 10px 2%;
	grid-template-columns: 49% 49%;
	align-items: center;
}

.quote_risks {
	padding: 15px 0px;
	border-bottom: 2px solid #c2bfbf;
}

.temp_payment_fail {
	width: 50%;
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	border: 1px solid;
	border-radius: 20px;
	padding: 5rem;
	margin: 2rem auto;
}

.section_title {
	position: absolute;
	top: -15px;
	background: #746a6a;
	padding: 5px 10px;
	color: white;
	border-radius: 5px;
}

fieldset {
	display: block;
	margin-inline-start: 2px;
	margin-inline-end: 2px;
	padding-block-start: 0.35em;
	padding-inline-start: 0.75em;
	padding-inline-end: 0.75em;
	padding-block-end: 0.625em;
	padding: 2rem;
	min-inline-size: min-content;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(189, 189, 189);
	border-image: initial;
	border-radius: 0.8rem;
}

legend {
	background: #746a6a;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
}

hr {
	margin: 2rem 0;
}

.custom_input {
	outline: 1px solid #c6c2c2;
	width: 100%;
	border-radius: 3px;
	height: 50px;
}

.loading_error_img {
	width: 150px;
	border-radius: 20px;
	margin-bottom: 2rem;
	box-shadow: 0px 4px 10px #a3a0a0;
}

.blink_me {
	animation: blinker 1.7s linear infinite;
	margin-top: 2rem !important;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
/* ul, .social-media_list_main-item{
	text-align: left;
    padding-left: 0px;
	list-style: none
} */

.menu_item_disabled {
	opacity: 1 !important;
}

.disable-scrolling {
	overflow: hidden;
}

.Puck div,
.Puck h2{
  font-size: 100%;
}